
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isLast(index: any): boolean {
      return index === this.crumbs.length - 1;
    },
    selected(crumb: any): void {
      this.$emit("selected", crumb);
    },
  },
});
