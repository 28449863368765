<template>
  <div v-if="roster" class="card w-75 mx-auto" style="max-height: 90vh">
    <div class="card-body">
      <!--            Body-->
      <!--              Table-->
      <div id="printArea">
        <div class="h3 mt-5 mb-3 text-center">{{ title }}</div>
        <table class="table table-bordered text-center">
          <thead class="shadow-sm">
            <tr>
              <th class="align-middle h5" style="min-width: 150px" scope="col">
                Date
              </th>

              <!--                      Is Holiday Head-->

              <th class="align-middle h5" style="" scope="col">Holiday</th>

              <!--                      Dynamic Column (Shifts) Table Head-->
              <th
                v-for="(item, index) in roster.shifts"
                :key="index"
                class="align-middle h5"
                style="min-width: 150px"
                scope="col"
              >
                {{ item.name }}({{ item.startTime + " - " + item.endTime }})
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="roster.days.length < 1">
              <td class="text-center py-4" colspan="5">
                <span class="h5 text-muted">No Roster Available</span>
              </td>
            </tr>
            <!--                    Date-->
            <tr
              v-else
              v-for="(day, dayIndex) in roster.days"
              :key="dayIndex"
              :class="`${day.isHoliday ? 'table-secondary' : ''}`"
            >
              <!--                      1st column Day and Date-->
              <td class="align-middle shadow-sm">
                <span class="lead">{{
                  new Date(day.date).toLocaleString("en-us", {
                    weekday: "long",
                  })
                }}</span>
                <div class="border-top">
                  {{ new Date(day.date).getDate() }}
                  {{
                    new Date(day.date).toLocaleString("en-us", {
                      month: "long",
                    })
                  }}
                  {{ new Date(day.date).getFullYear() }}
                </div>
              </td>

              <!--                      is Holiday-->
              <td class="align-middle">
                {{ day.isHoliday ? "Yes" : "No" }}
              </td>

              <!--                      Dynamic Column (Shifts) Table Content-->
              <td
                v-for="(shift, shiftIndex) in day.shifts"
                :key="shiftIndex"
                class="align-middle text-left"
              >
                <span
                  v-for="(employee, empIndex) in shift.employees"
                  :key="empIndex"
                >
                  <span>{{ employee.firstName }} {{ employee.lastName }}</span
                  ><span
                    v-if="
                      shift.employees.length > 1 ||
                      shift.employees.length != empIndex + 1
                    "
                    >,
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrintModal",

  data() {
    // Parameters that change depending on the type of dialogue
    return {
      roster: undefined, // Main content
      title: undefined,
    };
  },

  methods: {
    resetData() {
      this.roster = undefined;
    },

    show(opts = {}) {
      this.roster = opts.roster;
      this.title = opts.title;
      // Return promise so the caller can get results
      return new Promise((resolve) => {
        resolve();
      });
    },

    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;

      // Open the print window
      const wPrint = window.open("", "", "");

      wPrint.document.write(`
          <html>
            <head>
            <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css"
            integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N"
            crossorigin="anonymous">
            </head>
            <style>
            </style>
        <body onload="window.print();window.close()">${prtHtml}</body>
          </html>`);

      wPrint.document.close();
      this.resetData();
    },
  },
};
</script>

<style scoped></style>
