
import Breadcrumb from "@/components/Breadcrumb.vue";
import { defineComponent } from "vue";
import AuthStore from "@/store/auth-store";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import * as yup from "yup";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import RosterNavigation from "@/components/RosterNavigation.vue";
import PrintModal from "@/components/modals/PrintModal.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import DropdownComponent from "@/components/DropdownComponent.vue";
import { formatDate_h, notify } from "@/services/helpers";
import NewRoster from "@/views/roster/NewRoster.vue";
import ComplexRoster from "@/views/roster/ComplexRoster.vue";

// Type
type RosterRow = {
  [key: string]: any;
};

export default defineComponent({
  name: "Rosters",
  components: {
    ComplexRoster,
    NewRoster,
    LoadingComponent,
    Breadcrumb,
    ConfirmationModal,
    PrintModal,
    RosterNavigation,
    Dropdown: DropdownComponent,
  },

  data() {
    return {
      crumbs: ["Dashboard", "Rosters"],
      roles: AuthStore.getTokenData().Roles,
      api: new WorkForceService(),
      isCreatingRow: false,
      isCreatingRoster: false,
      editingRowIndex: -1,
      editingRosterIndex: -1,
      isEditing: false,
      employeeList: [],
      loading: false,
      initLoading: false,
      formatDate: formatDate_h,

      rolesEnum: {
        admin: "Admin",
        manager: "Manager",
        orgAdmin: "Org Admin",
      },

      schema: yup.object().shape({
        name: yup.string().required("Name is required!"),
      }),

      fields: { text: "firstName", value: "id" },

      rosters: [
        {
          id: -1,
          lastDate: "",
          shifts: [],
          days: [
            {
              isHoliday: false,
              shifts: [
                {
                  employees: [{}],
                },
              ],
            },
          ],
        },
      ],
      shiftList: [],
      authId: AuthStore.getTokenData().EmployeeId,

      tempRoster: {
        date: "",
        isHoliday: false,
        holidayType: "",
        shifts: [
          {
            shiftId: -1,
            shiftName: "",
            employees: [],
          },
        ],
      },

      // API Payload

      newRosterRow: {} as RosterRow,

      newRoster: {
        name: "",
      },
      newRowDate: "",
    };
  },

  async created() {
    await this.initialize();
  },
  methods: {
    async clearFilter() {
      this.resetFlag();
      await this.initialize();
    },

    async initialize() {
      this.initLoading = true;

      await this.fetch();
      await this.fetchRoster();
      this.initLoading = false;
    },
    resetFlag() {
      this.isEditing = false;
      this.isCreatingRow = false;
      this.isCreatingRoster = false;
      this.editingRowIndex = -1;
      this.editingRosterIndex = -1;
      this.newRosterRow = {};
      this.tempRoster.shifts = [];
    },

    isDisabled(roster: any) {
      if (roster.days.length > 0) {
        if (roster.days[roster.days.length - 1].date == roster.lastDate) {
          return false;
        }
      } else if (roster.lastDate == null) {
        return false;
      }
      return true;
    },

    toggleHoliday(rosterIndex: number, dayIndex: number) {
      if (this.isEditing) {
        this.rosters[rosterIndex].days[dayIndex].isHoliday = !this.rosters[
          rosterIndex
        ].days[dayIndex].isHoliday;
      }

      this.newRosterRow.isHoliday = !this.newRosterRow.isHoliday;
    },

    // fetch Employee list
    async fetch() {
      await this.api.get(`/Shifts`, false).then((response: any) => {
        this.shiftList = response.content;
      });

      await this.api
        .get(`/Employee/BelongToRole`, false)
        .then((response: any) => {
          this.employeeList = response.content.items;
        });
    },

    async fetchRoster() {
      await this.api.get(`/Rosters`, false).then((response: any) => {
        this.rosters = response.content;
      });
    },

    // Clear Filter
    async clear(rosterIndex: number) {
      await this.api.get(`/Rosters`, false).then((response: any) => {
        this.rosters[rosterIndex] = response.content[rosterIndex];
      });
    },

    // Filter Roster

    filter(event: any) {
      this.api
        .get(
          `/RosterDays/filters?StartDate=${event.startDate}&EndDate=${
            event.endDate
          }&RosterId=${event.id}&OrganisationId=${
            AuthStore.getTokenData().OrganisationId
          }`,
          false
        )
        .then((response: any) => {
          if (!response.isError) {
            this.rosters[event.rosterIndex].days = response.content;
          }
        });
    },

    cancelEdit(rosterIndex: number, dayIndex: number) {
      this.editingRowIndex = -1;
      this.editingRosterIndex = -1;
      if (this.isCreatingRow) {
        this.rosters[rosterIndex].days.splice(dayIndex, 1);
      } else {
        this.rosters[rosterIndex].days[dayIndex] = JSON.parse(
          JSON.stringify(this.tempRoster)
        );
      }

      this.resetFlag();
    },

    checkDuplicate() {
      const employeeMap = new Map();

      // Flag to track whether duplicate employee found
      let hasDuplicateEmployee = false;

      // Iterate through shifts
      for (const shift of this.newRosterRow.shifts) {
        // Iterate through employees in the current shift
        for (const employee of shift.employees) {
          // Check if employee ID is already in the map
          if (employee.status != 2) {
            if (employeeMap.has(employee.employeeId)) {
              hasDuplicateEmployee = true;
              break;
            } else {
              // Add employee ID to the map
              if (employee.status != 2) {
                employeeMap.set(employee.employeeId, true);
              }
            }
          }
        }
        // If a duplicate is found, no need to continue checking other shifts
        if (hasDuplicateEmployee) {
          break;
        }
      }

      return hasDuplicateEmployee;
    },

    // Save Day
    async saveRow(id: number, rosterIndex: number) {
      this.loading = true;

      if (this.checkDuplicate()) {
        notify(
          "You have added duplicate employee on the same or multiple shift.",
          "Warning",
          "warning"
        );
      }

      await this.api
        .postOrPut<void>(
          id ? "/RosterDays/" + id.toString() : "/RosterDays",
          this.newRosterRow,
          id ? id.toString() : undefined
        )
        .then((response: any) => {
          if (!response.isError) {
            if (!this.isEditing) {
              this.newRowDate = response.data.date;
            }
            if (!id) {
              this.api.get(`/Rosters`, false).then((response: any) => {
                this.rosters[rosterIndex] = response.content[rosterIndex];
              });
            }
            this.resetFlag();
          } else {
            this.changeEmpStatusColor(response);
            notify(response.message, "Attention", "warning");
          }
          this.loading = false;
        });
    },

    changeEmpStatusColor(response: any) {
      response.data.forEach((employee: any) => {
        this.newRosterRow.shifts.forEach((shift: any) => {
          shift.employees.forEach((rEmp: any) => {
            if (rEmp.employeeId == employee.employeeId) {
              rEmp["isOnLeave"] = true;
            }
          });
        });
      });
    },

    cloneRow(day: any, rosterIndex: number, dayIndex: number) {
      this.isCreatingRow = true;
      this.editingRosterIndex = rosterIndex;
      this.editingRowIndex = dayIndex + 1;
      let today = this.formatDate(new Date());
      let lastDate = this.formatDate(
        new Date(this.rosters[rosterIndex].lastDate.substring(0, 10))
      );

      let data = JSON.parse(JSON.stringify(day));

      if (today <= lastDate) {
        let date = new Date(
          this.formatDate(this.rosters[rosterIndex].lastDate)
        );
        date.setDate(date.getDate() + 1);
        data.date = this.formatDate(date);
      } else {
        let date = this.formatDate(new Date());
        data.date = this.formatDate(date);
      }

      data.id = 0;
      data.organisationId = 0;
      this.newRosterRow = data;
      this.rosters[rosterIndex].days.push(data);
    },

    newRow(rosterIndex: number) {
      this.isCreatingRow = true;
      this.editingRowIndex = this.rosters[rosterIndex].days.length;
      this.editingRosterIndex = rosterIndex;
      let date;

      // Checks if the roster is freshly created or old
      if (this.rosters[rosterIndex].days.length > 0) {
        let today = new Date().toISOString().substring(0, 10);
        let lastDate = this.formatDate(
          new Date(this.formatDate(this.rosters[rosterIndex].lastDate))
        );

        // Checks if the roster has any future date
        if (today <= lastDate) {
          date = new Date(this.formatDate(this.rosters[rosterIndex].lastDate));
          date.setDate(date.getDate() + 1);
        } else {
          date = this.formatDate(new Date());
        }
      } else {
        date = this.formatDate(new Date());
      }

      let data = {
        date: this.formatDate(date),
        id: 0,
        rosterId: this.rosters[rosterIndex].id,
        organisationId: 0,
        isHoliday: false,
        holidayType: "",
        shifts: [
          {
            shiftId: -1,
            shiftName: "",
            employees: [],
          },
        ],
      };

      this.rosters[rosterIndex].shifts.forEach((item) => {
        let shift = {
          shiftId: item["id"],
          shiftName: item["name"],
          employees: [],
        };
        data.shifts.push(shift);
      });

      data.shifts.splice(0, 1);
      this.newRosterRow = data;
      this.rosters[rosterIndex].days.push(data);
      // }
    },

    editRow(day: any, rosterIndex: number, dayIndex: number) {
      this.tempRoster = JSON.parse(JSON.stringify(day));
      this.editingRowIndex = dayIndex;
      this.isEditing = true;
      this.editingRosterIndex = rosterIndex;
      this.newRosterRow = JSON.parse(
        JSON.stringify(this.rosters[rosterIndex].days[dayIndex])
      );
    },

    isOldEmployee(shiftIndex: number, employeeId: number) {
      let flag = false;
      this.tempRoster.shifts[shiftIndex].employees.forEach((item: any) => {
        if (item.employeeId == employeeId) {
          flag = true;
        }
      });
      return flag;
    },

    setEmployee(
      e: any,
      rosterIndex: number,
      dayIndex: number,
      shiftIndex: number
    ) {
      let data = {};
      if (this.isEditing) {
        data = {
          employeeId: e.id,
          firstName: e.firstName,
          lastName: e.lastName,
          status: this.isOldEmployee(shiftIndex, e.id) ? 0 : 1, // Checks if it is old employee
        };

        // Push to roster for view - in edit mode the "newRosterRow" does not maintain reactivity
        this.rosters[rosterIndex].days[dayIndex].shifts[
          shiftIndex
        ].employees.push(data);
      } else {
        data = {
          employeeId: e.id,
          firstName: e.firstName,
          lastName: e.lastName,
        };
      }

      // push to newRosterRow this will be sent to api
      this.newRosterRow.shifts[shiftIndex].employees.push(data);
    },

    removeEmployee(
      rosterIndex: number,
      dayIndex: number,
      shiftIndex: number,
      empIndex: number,
      empId: number
    ) {
      let index = this.newRosterRow.shifts[shiftIndex].employees.findIndex(
        (object: any) => {
          return object.employeeId === empId;
        }
      );

      if (this.isEditing) {
        this.rosters[rosterIndex].days[dayIndex].shifts[
          shiftIndex
        ].employees.splice(empIndex, 1);

        // While editing if it is old emp -> status = 2 (backend will handel the deletion) else if it was new then that employee will be removed
        if (this.isOldEmployee(shiftIndex, empId)) {
          this.newRosterRow.shifts[shiftIndex].employees[index].status = 2;
        } else {
          this.newRosterRow.shifts[shiftIndex].employees.splice(index, 1);
        }
      } else {
        this.rosters[rosterIndex].days[dayIndex].shifts[
          shiftIndex
        ].employees.splice(empIndex, 1);
      }
    },

    // Save Roster
    rosterCreated() {
      this.fetchRoster();
      this.resetFlag();
    },

    // Delete Roster
    async deleteRoster(rosterIndex: number, rosterId: number) {
      const modal = await (this.$refs["confirmDialogue"] as any).show({
        title: "Attention!",
        message: "Are you sure you want to delete this roster?",
        okButton: "Delete",
        cancelButton: "Cancel",
        theme: "danger",
        type: "confirmation",
      });

      if (modal) {
        this.api.delete("/Rosters/" + rosterId).then((response: any) => {
          if (response.isSuccess) {
            this.rosters.splice(rosterIndex, 1);
            this.resetFlag();
            notify("Roster deleted successfully.", "Success", "success");
          } else {
            notify(response.errors.toString(), "Attention", "warning");
          }
        });
      } else {
        return false;
      }
    },

    employeeStatusColor(employee: any, rosterIndex: number, rowIndex: number) {
      let count = 0;

      this.rosters[rosterIndex].days[rowIndex].shifts.forEach((shift: any) => {
        shift.employees.forEach((emp: any) => {
          if (employee.employeeId == emp.employeeId) {
            count++;
          }
        });
      });

      return employee.employeeId == this.authId
        ? "badge-primary-dark"
        : employee.isOnLeave
        ? "badge-danger"
        : count > 1
        ? "badge-warning"
        : "badge-secondary";
    },

    // Print
    async loadPrintModal(printData: any) {
      let startDateObject = new Date(printData.days[0].date);
      let startDate =
        startDateObject.getDate() +
        " " +
        startDateObject.toLocaleString("en-us", { month: "long" }) +
        " " +
        startDateObject.getFullYear();

      let endDateObj = new Date(printData.days[printData.days.length - 1].date);
      let endDate =
        endDateObj.getDate() +
        " " +
        endDateObj.toLocaleString("en-us", { month: "long" }) +
        " " +
        endDateObj.getFullYear();

      await (this.$refs["printRef"] as any).show({
        roster: printData,
        title: `Roster Details ( ${startDate} - ${endDate})`,
      });

      (this.$refs["printRef"] as any).print();
    },
  },
});
