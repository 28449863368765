import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "card border-0 mb-3" }
const _hoisted_2 = { class: "card-header border-0 py-2 d-flex justify-content-between" }
const _hoisted_3 = { class: "breadcrumb px-0 mx-0 align-items-center mb-0 bg-light" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", null, [
        _createElementVNode("ol", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.crumbs, (crumb, ci) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: ci,
              class: "breadcrumb-item",
              to: { name: crumb }
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", {
                  class: _normalizeClass(["btn btn-link py-0", { disabled: _ctx.isLast(ci) }]),
                  onClick: ($event: any) => (_ctx.selected(crumb))
                }, _toDisplayString(crumb), 11, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "buttons")
      ])
    ])
  ]))
}