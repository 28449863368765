import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "d-none d-lg-flex" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "d-none d-md-block d-lg-none" }
const _hoisted_8 = { class: "mb-2" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "d-sm-block d-md-none" }
const _hoisted_13 = { class: "mb-2 ml-2" }
const _hoisted_14 = { class: "ml-2 mb-2" }
const _hoisted_15 = ["disabled"]
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "ml-2" }
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-xs mr-2",
          style: {"min-width":"80px"},
          onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)), ["prevent"])),
          disabled: !!!_ctx.lastDate || _ctx.filterDate.length < 1
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "mr-1",
            icon: "angle-left"
          }),
          _createTextVNode(" Prev Week ")
        ], 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Datepicker, {
          uid: "navigationDate",
          inputClassName: "dp-xs",
          calendarCellClassName: "dp-round-cell",
          menuClassName: "dp-menu",
          placeholder: "Select a date to navigate",
          autoApply: "",
          modelValue: _ctx.filterDate,
          "onUpdate:modelValue": [
            _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterDate) = $event)),
            _cache[2] || (_cache[2] = ($event: any) => (_ctx.filter($event, _ctx.roster.id, _ctx.roster.rosterIndex)))
          ],
          range: "",
          disabled: !!!_ctx.lastDate,
          "auto-range": "6",
          format: "dd/MM/yyyy",
          clearable: false,
          enableTimePicker: false,
          multiCalendars: ""
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("span", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-xs ml-2",
          style: {"min-width":"80px"},
          disabled: !!!_ctx.lastDate || _ctx.filterDate.length < 1,
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent"]))
        }, [
          _createTextVNode(" Next Week "),
          _createVNode(_component_font_awesome_icon, {
            class: "ml-1",
            icon: "angle-right"
          })
        ], 8, _hoisted_5)
      ]),
      _createElementVNode("span", null, [
        (this.formatDate(_ctx.lastDate) > this.formatDate(_ctx.todayDate))
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary btn-xs ml-2",
              style: {"min-width":"80px"},
              onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.today && _ctx.today(...args)), ["prevent"])),
              disabled: !!!_ctx.lastDate
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "mr-1",
                icon: "history"
              }),
              _createTextVNode(" Today ")
            ], 8, _hoisted_6))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", null, [
        (_ctx.filterDate.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-danger btn-xs ml-2",
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.clear(_ctx.roster.rosterIndex)), ["prevent"])),
              style: {"min-width":"80px"}
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "mr-1",
                icon: "times-circle"
              }),
              _createTextVNode(" Latest ")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Datepicker, {
          uid: "navigationDateMbl",
          inputClassName: "dp-xs",
          calendarCellClassName: "dp-round-cell",
          menuClassName: "dp-menu",
          placeholder: "Select a date to navigate",
          autoApply: "",
          modelValue: _ctx.filterDate,
          "onUpdate:modelValue": [
            _cache[6] || (_cache[6] = ($event: any) => ((_ctx.filterDate) = $event)),
            _cache[7] || (_cache[7] = ($event: any) => (_ctx.filter($event, _ctx.roster.id, _ctx.roster.rosterIndex)))
          ],
          range: "",
          disabled: !!!_ctx.lastDate,
          "auto-range": "6",
          format: "dd/MM/yyyy",
          clearable: false,
          enableTimePicker: false,
          multiCalendars: ""
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _createElementVNode("span", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-xs mr-2",
          style: {"min-width":"80px"},
          onClick: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)), ["prevent"])),
          disabled: !!!_ctx.lastDate || _ctx.filterDate.length < 1
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "mr-1",
            icon: "angle-left"
          }),
          _createTextVNode(" Prev Week ")
        ], 8, _hoisted_9)
      ]),
      _createElementVNode("span", null, [
        _createElementVNode("button", {
          class: "btn btn-secondary btn-xs ml-2",
          style: {"min-width":"80px"},
          disabled: !!!_ctx.lastDate || _ctx.filterDate.length < 1,
          onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent"]))
        }, [
          _createTextVNode(" Next Week "),
          _createVNode(_component_font_awesome_icon, {
            class: "ml-1",
            icon: "angle-right"
          })
        ], 8, _hoisted_10)
      ]),
      _createElementVNode("span", null, [
        (_ctx.lastDate > _ctx.todayDate)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary btn-xs ml-2",
              style: {"min-width":"80px"},
              onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.today && _ctx.today(...args)), ["prevent"])),
              disabled: !!!_ctx.lastDate
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "mr-1",
                icon: "history"
              }),
              _createTextVNode(" Today ")
            ], 8, _hoisted_11))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("span", null, [
        (_ctx.filterDate.length > 0)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-danger btn-xs ml-2",
              onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.clear(_ctx.roster.rosterIndex)), ["prevent"])),
              style: {"min-width":"80px"}
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "mr-1",
                icon: "times-circle"
              }),
              _createTextVNode(" Latest ")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_Datepicker, {
          uid: "navigationDateMblXs",
          disabled: !!!_ctx.lastDate,
          modelValue: _ctx.filterDate,
          "onUpdate:modelValue": [
            _cache[12] || (_cache[12] = ($event: any) => ((_ctx.filterDate) = $event)),
            _cache[13] || (_cache[13] = ($event: any) => (_ctx.filter($event, _ctx.roster.id, _ctx.roster.rosterIndex)))
          ],
          range: "",
          autoApply: "",
          inputClassName: "dp-xs",
          calendarCellClassName: "dp-round-cell",
          menuClassName: "dp-menu",
          placeholder: "Select a date to navigate",
          "auto-range": "6",
          format: "dd/MM/yyyy",
          clearable: false,
          enableTimePicker: false
        }, null, 8, ["disabled", "modelValue"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("span", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-xs mr-2",
            style: {"min-width":"80px"},
            onClick: _cache[14] || (_cache[14] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.prev && _ctx.prev(...args)), ["prevent"])),
            disabled: !!!_ctx.lastDate || _ctx.filterDate.length < 1
          }, [
            _createVNode(_component_font_awesome_icon, {
              class: "mr-1",
              icon: "angle-left"
            }),
            _createTextVNode(" Prev Week ")
          ], 8, _hoisted_15)
        ]),
        _createElementVNode("span", null, [
          _createElementVNode("button", {
            class: "btn btn-secondary btn-xs ml-2",
            style: {"min-width":"80px"},
            disabled: !!!_ctx.lastDate || _ctx.filterDate.length < 1,
            onClick: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)), ["prevent"]))
          }, [
            _createTextVNode(" Next Week "),
            _createVNode(_component_font_awesome_icon, {
              class: "ml-1",
              icon: "angle-right"
            })
          ], 8, _hoisted_16)
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("span", null, [
          (_ctx.lastDate > _ctx.todayDate)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-primary btn-xs",
                style: {"min-width":"80px"},
                onClick: _cache[16] || (_cache[16] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.today && _ctx.today(...args)), ["prevent"])),
                disabled: !!!_ctx.lastDate
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "mr-1",
                  icon: "history"
                }),
                _createTextVNode(" Today ")
              ], 8, _hoisted_18))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("span", null, [
          (_ctx.filterDate.length > 0)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-danger btn-xs ml-2",
                onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (_ctx.clear(_ctx.roster.rosterIndex)), ["prevent"])),
                style: {"min-width":"80px"}
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "mr-1",
                  icon: "times-circle"
                }),
                _createTextVNode(" Latest ")
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}