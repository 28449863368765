
import { computed, defineComponent, ref } from "vue";
import Dropdown from "@/components/DropdownComponent.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import AuthStore from "@/store/auth-store";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import { IAssignee, IShift } from "@/types/Roster";
import { hourDiff } from "../../services/helpers";

export default defineComponent({
  name: "RosterCell",
  methods: { hourDiff },
  components: {
    Datepicker,
    Dropdown,
    Form,
    Field,
    ErrorMessage,
    ConfirmationModal,
  },
  props: {
    shiftProp: {
      type: Object,
      required: true,
    },
    day: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    employeeList: {
      type: Array,
      required: true,
    },
    rosterIndex: {
      type: Number,
      required: true,
    },
    dayIndex: {
      type: Number,
      required: true,
    },
    cellIndex: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const c_modal = ref();
    const resetFlags = () => {
      store.commit("resetFlags");
    };

    const toTimeString = (time: any) => {
      if (time == "" || !time) {
        return "";
      }
      return `${time.hours < 10 ? "0" : ""}${time.hours}:${
        time.minutes < 10 ? "0" : ""
      }${time.minutes}`;
    };

    const toTimeObject = (time: string) => {
      if (time == "" || !time) {
        return "";
      }

      let timesArr = time.split(":");
      return {
        hours: parseInt(timesArr[0]),
        minutes: parseInt(timesArr[1]),
      };
    };

    const authId = AuthStore.getTokenData().EmployeeId;

    const rosters = computed(() => store.getters.rosters);
    const editingRosterIndex = computed(() => store.getters.editingRosterIndex);
    const editingDayIndex = computed(() => store.getters.editingDayIndex);

    // Cell Model
    const cellModel = ref({
      startTime: toTimeObject(
        rosters.value[props.rosterIndex].days[props.dayIndex].shifts[
          props.cellIndex
        ].startTime
      ),

      endTime: toTimeObject(
        rosters.value[props.rosterIndex].days[props.dayIndex].shifts[
          props.cellIndex
        ].endTime
      ),
      totalHours: 2,
      assignedEmployees: [] as IAssignee[],
    });

    const saveCell = () => {
      store.dispatch("saveRosterCell");
    };

    const deleteCell = async () => {
      const confirmed = await c_modal.value.show({
        title: "Attention!",
        message: "Are you sure you want to delete this shift?",
        okButton: "Delete",
        cancelButton: "Cancel",
        theme: "danger",
        type: "confirmation",
      });
      if (confirmed) {
        store.commit("removeCell", {
          rosterIndex: props.rosterIndex,
          dayIndex: props.dayIndex,
          cellIndex: props.cellIndex,
        });
      }
    };

    const editCell = () => {
      const payload = {
        editingRosterIndex: props.rosterIndex,
        editingDayIndex: props.dayIndex,
        editingCellIndex: props.cellIndex,
        dayEditFlag: false,
      };
      cancel();
      store.commit("createBackup", payload);
      store.commit("editCell", payload);
    };

    const addShiftTime = () => {
      const data = {
        startTime: toTimeString(cellModel.value.startTime),
        endTime: toTimeString(cellModel.value.endTime),
      };

      store.commit("addShiftTime", data);
    };

    const setEmployee = (e: any, cellIndex: number) => {
      if (props.isEditing) {
        store.commit("addAssignee", e);
      }
    };

    const shift = ref(props.shiftProp);

    const removeAssignee = (id: number) => {
      store.commit("removeAssignee", id);
    };

    const cancel = () => {
      store.commit("cancel");
      store.commit("resetFlags");
    };

    const employeeStatusColor = (employee: any) => {
      let count = 0;
      const { rosterIndex, dayIndex } = props;
      rosters.value[rosterIndex].days[dayIndex].shifts.forEach((shift: any) => {
        shift.assignedEmployees.forEach((emp: any) => {
          if (employee.employeeId == emp.employeeId) {
            count++;
          }
        });
      });

      return employee.employeeId == authId
        ? "badge-primary-dark"
        : employee.isOnLeave
        ? "badge-danger"
        : count > 1
        ? "badge-warning"
        : "badge-secondary";
    };

    return {
      resetFlags,
      shift,
      deleteCell,
      editCell,
      setEmployee,
      removeAssignee,
      c_modal,
      cancel,
      employeeStatusColor,
      editingRosterIndex,
      editingDayIndex,
      rosters,
      cellModel,
      addShiftTime,
      saveCell,
    };
  },
});
