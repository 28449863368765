<template>
  <transition name="bounce">
    <div class="popup-modal" v-if="isVisible">
      <div class="modal-wrapper-outer">
        <div class="modal-wrapper">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
  <div :class="isVisible ? 'backdrop on' : 'backdrop'"></div>
</template>

<script>
export default {
  name: "PopupModal",

  data: () => ({
    isVisible: false,
  }),

  methods: {
    open() {
      this.isVisible = true;
    },

    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style scoped></style>
