<template>
  <div v-if="options">
    <!-- Dropdown Input -->
    <div :class="`custom-dropdown${size ? '-' + size : ''}`">
      <div class="icon-left" v-if="icon">
        <font-awesome-icon class="input-icon text-primary" :icon="icon" />
      </div>
      <div
        class="icon-clear"
        v-if="inputText && clearable"
        @click.prevent="clear"
      >
        <font-awesome-icon class="input-icon text-muted" icon="times" />
      </div>
      <div
        @focus="showOptions()"
        @focusout="exit()"
        tabindex="-1"
        v-if="!inputClass.match('is-invalid')"
        class="icon-right"
      >
        <font-awesome-icon
          :class="`input-icon text-secondary ${optionsShown ? 'active' : ''}`"
          icon="chevron-down"
        />
      </div>

      <input
        ref="dropdown-toggle"
        :class="`${
          disabled ? 'disabled' : 'form-control-dropdown  bg-white'
        } ${inputClass}`"
        :name="name"
        @click="showOptions()"
        @keydown.down.prevent="navigateDown"
        @keydown.up.prevent="navigateUp"
        @keydown.enter.prevent="handleEnterPress"
        v-model="inputText"
        :readonly="!filterable"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
      />

      <!-- Dropdown Menu -->
      <div
        ref="dropdown-menu"
        :class="`dropdown-content-wrapper ${reverse ? 'always-reverse' : ''}`"
        v-show="optionsShown"
      >
        <div
          :class="`${activeClas(index)} custom-dropdown-item${
            size ? '-' + size : ''
          } ${
            option[this.fields[0]] == selected[this.fields[0]]
              ? 'bg-primary-light text-primary-dark'
              : ''
          }`"
          @click="selectOption(option)"
          v-for="(option, index) in filteredOptions"
          :key="index"
        >
          {{ renderText(option) }}
        </div>
        <div
          v-if="filteredOptions == 0"
          :class="`text-muted disabled custom-dropdown-item${
            size ? '-' + size : ''
          }`"
        >
          No Content
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DropdownComponent",
  emits: ["selected", "update:modelValue", "cleared", "filter"],
  props: {
    inputClass: {
      type: String,
      require: false,
      default: "",
    },
    size: {
      type: String,
      require: false,
      default: null,
    },
    icon: {
      type: String,
      require: false,
    },
    filterable: {
      type: Boolean,
      require: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      require: false,
      default: false,
    },
    preSelected: {
      type: [Number, String],
      require: false,
    },
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      note: "Options of dropdown. An array of options with id and name",
    },
    fields: {
      type: Array,
      required: true,
      note: "Map of keys for the option array",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      note: "Max items showing",
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    separator: {
      type: String,
      default: " ",
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
      inputText: "",
      activeIndex: -1,
    };
  },
  async created() {
    // Add a global click event listener
    document.addEventListener("click", this.handleGlobalClick);

    let option = await this.getOption(await this.preSelected);
    this.selected = option ? option : {};
    this.inputText = option ? this.renderText(option) : "";
    if (option) {
      this.$emit("selected", this.selected);
      this.$emit(
        "update:modelValue",
        option ? this.selected[this.fields[0]] : undefined
      );
    }
  },
  beforeUnmount() {
    // Remove the global click event listener when the component is destroyed
    document.removeEventListener("click", this.handleGlobalClick);
  },
  computed: {
    filteredOptions: function () {
      if (this.filterable) {
        const filtered = [];
        const regOption = new RegExp(this.inputText, "ig");
        for (const option of this.options) {
          if (this.inputText.length < 1 || this.search(option, regOption)) {
            if (this.maxItem) {
              if (filtered.length < this.maxItem) {
                filtered.push(option);
              }
            } else {
              filtered.push(option);
            }
          }
        }
        return filtered;
      }
      return this.options;
    },
  },
  methods: {
    // searches in all given attribute in the fields prop
    search(option, regOption) {
      let flag = false;
      this.fields.forEach((field, index) => {
        if (index != 0) {
          // skips the first filed as it is id field
          if (option[field].match(regOption)) {
            flag = true; // if match change flag
          }
        }
      });
      return flag;
    },

    // searches the given preselected id
    getOption(value) {
      let option = undefined;
      this.options.forEach((item) => {
        if (item[this.fields[0]] === value) {
          option = item;
        }
      });

      return option;
    },

    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      this.inputText = this.renderText(option);
      this.$emit("selected", this.selected);
      this.$emit("update:modelValue", this.selected[this.fields[0]]);
    },

    // sets the input and drop down text in case of multiple value attribute
    renderText(option) {
      let text = "";
      this.fields.forEach((key, index) => {
        if (index != 0 && option[key]) {
          // skips the first filed as it is id field
          text =
            text +
            option[key] +
            (index !== this.fields.length - 1 ? this.separator : "");
        }
      });

      return text;
    },

    async clear() {
      await this.$emit("cleared", this.selected);
      this.$emit("update:modelValue", undefined);
      this.selected = {};
      this.inputText = "";
    },

    async showOptions() {
      if (!this.disabled) {
        if (this.filterable) {
          this.inputText = "";
        }

        let t;
        let el = this.$refs["dropdown-toggle"];
        let menu = this.$refs["dropdown-menu"];
        let scrollTop = (((t = document.documentElement) ||
          (t = document.body.parentNode)) &&
        typeof t.scrollTop == "number"
          ? t
          : document.body
        ).scrollTop;
        let topOffset = el.getBoundingClientRect().top;
        let relativeOffset = topOffset - scrollTop;
        let windowHeight = window.innerHeight;
        let bottom = windowHeight - topOffset;

        if (bottom > 300) {
          menu.classList.remove("reverse");
        } else {
          menu.classList.add("reverse");
        }

        // Trigger a click event to open the dropdown
        el.click();

        this.optionsShown = true;
      }
    },
    // Global click event handler
    handleGlobalClick(event) {
      // Check if the clicked element is outside the dropdown
      const isOutsideDropdown =
        !this.$refs["dropdown-toggle"].contains(event.target) &&
        !this.$refs["dropdown-menu"].contains(event.target);

      // If the click is outside the dropdown, close the menu
      if (isOutsideDropdown) {
        this.exit();
      }
    },

    exit() {
      this.activeIndex = -1;
      if (typeof this.selected[this.fields[0]] === "undefined") {
        this.selected = {};
        this.inputText = "";
      } else {
        this.inputText = this.renderText(this.selected);
        this.$emit("update:modelValue", this.selected[this.fields[0]]);
      }

      this.optionsShown = false;
      this.$refs["dropdown-toggle"].blur();
    },
    // Selecting when pressing Enter
    handleEnterPress() {
      if (this.activeIndex > -1) {
        this.selectOption(this.filteredOptions[this.activeIndex]);
        this.exit();
      }
    },

    activeClas(index) {
      if (index == this.activeIndex) {
        return "dropdown-active";
      }
    },

    navigateDown() {
      if (this.activeIndex < this.filteredOptions.length - 1) {
        this.activeIndex++;
      }
    },
    navigateUp() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
  },
  watch: {
    inputText() {
      if (this.filterable) {
        this.$emit("filter", this.inputText);
      }
    },

    // Watch if pre selected changes
    async preSelected() {
      let option = await this.getOption(this.preSelected);
      this.selected = option ? option : {};
      this.inputText = option ? this.renderText(option) : "";
      if (option) {
        this.$emit("selected", this.selected);
        this.$emit(
          "update:modelValue",
          option ? this.selected[this.fields[0]] : undefined
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  display: block;
  margin: auto;
  .dropdown-input {
    background: #fff;
    cursor: pointer;
    border: 1px solid #e7ecf5;
    border-radius: 3px;
    color: #333;
    display: block;
    font-size: 0.8em;
    padding: 6px;
    min-width: 250px;
    max-width: 250px;
    &:hover {
      background: #f8f8fa;
    }
  }

  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
</style>
