<template>
  <popup-modal ref="popup">
    <template v-if="type == 'confirmation' || type == 'notification'">
      <div class="modal-dialog">
        <div class="modal-content px-5" style="max-height: 90vh">
          <div class="modal-body scrollable">
            <!--                Heading-->
            <div
              v-if="title"
              class="d-flex align-items-center justify-content-center py-2"
            >
              <!--                Title-->
              <span class="h4 text-primary m-0">{{ title }}</span>
            </div>

            <!--                Icon-->
            <div
              v-if="icon"
              class="d-flex align-items-center justify-content-center"
            >
              <span :class="`icon-mod text-${icon}`">
                <font-awesome-icon :icon="icons[icon]" />
              </span>
            </div>

            <!--                    Description-->
            <div
              :class="`d-flex align-items-center justify-content-center ${
                icon ? 'py-2' : 'py-4'
              }`"
            >
              <div class="h6 text-muted">
                {{ message }}
              </div>
            </div>

            <!--              Buttons-->
            <div class="row py-2">
              <div class="col" v-if="type == 'confirmation'">
                <button
                  @click="_cancel"
                  class="btn btn-secondary btn-block"
                  data-dismiss="modal"
                >
                  {{ cancelButton }}
                </button>
              </div>
              <div class="col text-center">
                <button
                  @click="_confirm"
                  :class="`btn btn-primary ${
                    type == 'notification' ? 'w-50' : 'btn-block'
                  }`"
                  data-dismiss="modal"
                >
                  {{ okButton }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </popup-modal>
</template>

<script>
import PopupModal from "./PopupModal.vue";

export default {
  name: "ConfirmationModal",

  components: { PopupModal },

  data: () => ({
    // Parameters that change depending on the type of dialogue
    icons: {
      warning: "exclamation-triangle",
      danger: "times-circle",
      success: "check-circle",
      info: "info-circle",
    },
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
    cancelButton: "Cancel", // text for cancel button
    icon: undefined, // name of icon
    type: undefined, // type: Confirmation || Notification

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    resetData() {
      this.title = undefined;
      this.message = undefined;
      this.okButton = undefined;
      this.cancelButton = "Cancel";
      this.icon = undefined;
      this.type = undefined;
    },
    async show(opts = {}) {
      this.type = opts.type;
      this.title = opts.title;
      this.message = opts.message;
      this.icon = opts.icon;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }

      if (opts.theme) {
        this.theme = opts.theme;
      }

      if (opts.icon) {
        this.icon = opts.icon;
      }

      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
      this.resetData();
    },

    _cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      this.resetData();
      // Or you can throw an error
      // this.rejectPromise(new Error('User cancelled the dialogue'))
    },
  },
};
</script>

<style scoped></style>
