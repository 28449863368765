
import { defineComponent } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import { formatDate_h } from "@/services/helpers";

export default defineComponent({
  name: "RosterNavigation",
  components: {
    Datepicker,
  },
  props: {
    roster: {
      type: Object,
      required: true,
    },
    newRowDate: {
      type: String,
      required: false,
    },
  },
  emits: ["filter", "clear"],
  data() {
    return {
      filterDate: [] as any,
      lastDate: this.roster.rosterData.lastDate
        ? new Date(this.roster.rosterData.lastDate)
        : null,
      todayDate: new Date(),
      formatDate: formatDate_h,
    };
  },

  methods: {
    // Filter Roster
    filter(args: any, id: number, rosterIndex: number) {
      let sDate = new Date(this.formatDate(args[0]));
      let eDate = new Date(this.formatDate(args[1]));
      this.filterDate = [sDate, eDate];

      let endDate = this.formatDate(eDate);
      let startDate = this.formatDate(sDate);

      this.$emit("filter", {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
        id: id,
        rosterIndex: rosterIndex,
      });
    },

    clear(rosterIndex: number) {
      this.filterDate = [];
      this.$emit("clear", rosterIndex);
    },

    today() {
      if (this.formatDate(this.lastDate)! < this.formatDate(this.todayDate)) {
        return false;
      }
      let endDate = new Date(this.formatDate(new Date()));
      // this.todayDate
      endDate.setDate(endDate.getDate() + 6);

      this.filterDate = [this.formatDate(this.todayDate), endDate];

      this.$emit("filter", {
        startDate: this.formatDate(this.todayDate),
        endDate: this.formatDate(endDate),
        id: this.roster.id,
        rosterIndex: this.roster.rosterIndex,
      });
    },

    next() {
      let startDate = new Date(this.formatDate(this.filterDate[0]));
      startDate.setDate(startDate.getDate() + 6);

      let endDate = new Date(this.formatDate(startDate));
      endDate.setDate(endDate.getDate() + 6);
      this.filterDate = [this.formatDate(startDate), this.formatDate(endDate)];

      this.$emit("filter", {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
        id: this.roster.id,
        rosterIndex: this.roster.rosterIndex,
      });
    },

    prev() {
      let startDate = new Date(this.formatDate(this.filterDate[0]));
      startDate.setDate(startDate.getDate() - 6);

      let endDate = new Date(this.formatDate(startDate));
      endDate.setDate(endDate.getDate() + 6);
      this.filterDate = [this.formatDate(startDate), this.formatDate(endDate)];

      this.$emit("filter", {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
        id: this.roster.id,
        rosterIndex: this.roster.rosterIndex,
      });
    },
  },
  watch: {
    roster() {
      if (this.roster.rosterData.lastDate) {
        this.lastDate = new Date(
          this.formatDate(this.roster.rosterData.lastDate)
        );
      }
    },
    newRowDate() {
      this.filterDate = [];
    },
  },
});
