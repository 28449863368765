<template>
  <div v-if="options">
    <!-- Dropdown Input -->
    <!--    icon-right: as for dropdown the right icon is always needed, so the flag is hard coded-->
    <div
      :class="`bg-white custom-dropdown${size ? '-' + size : ''} icon-right ${
        icon ? 'icon-left' : ''
      }`"
    >
      <div class="icon-left" v-if="icon">
        <font-awesome-icon class="input-icon text-primary" :icon="icon" />
      </div>
      <div
        class="icon-clear cursor-pointer"
        v-if="(inputText || selectedArr.length > 0) && clearable"
        @click.prevent="clear"
      >
        <font-awesome-icon class="input-icon text-muted" icon="times" />
      </div>
      <div
        @focus="showOptions()"
        @focusout="exit()"
        tabindex="-1"
        v-if="!inputClass.match('is-invalid')"
        class="icon-right"
      >
        <font-awesome-icon
          :class="`input-icon text-secondary ${optionsShown ? 'active' : ''}`"
          icon="chevron-down"
        />
      </div>

      <input
        ref="dropdown-toggle"
        :class="`${
          multiselect && selectedArr.length
            ? 'border-bottom rounded-0 rounded-top'
            : ''
        } ${disabled ? 'disabled' : 'form-control-dropdown'} ${inputClass}`"
        :name="name"
        @click="showOptions()"
        @keydown.down.prevent="navigateDown"
        @keydown.up.prevent="navigateUp"
        @keydown.enter.prevent="handleEnterPress"
        v-model="inputText"
        :readonly="!filterable"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
      />

      <!-- Dropdown Menu -->
      <Transition name="squeeze-down">
        <div
          ref="dropdown-menu"
          :class="`dropdown-content-wrapper ${reverse ? 'always-reverse' : ''}`"
          v-show="optionsShown"
        >
          <div
            :class="`mx-1 rounded my-1 custom-dropdown-item ${
              size ? size : ''
            } ${
              isSelected(option)
                ? 'bg-primary-light text-primary-dark fw-semibold'
                : 'not-selected'
            }`"
            @mousedown="selectOption(option, index)"
            v-for="(option, index) in filteredOptions"
            :key="index"
          >
            <div class="d-flex justify-content-between">
              <div class="">
                <slot name="option" :item="option" :index="index">
                  {{ renderText(option) }}
                </slot>
              </div>
              <div class="">
                <slot name="details" :item="option" :index="index"></slot>
              </div>
            </div>
          </div>
          <div
            v-if="filteredOptions == 0"
            :class="`text-muted disabled custom-dropdown-item${
              size ? '-' + size : ''
            }`"
          >
            No Content
          </div>
        </div>
      </Transition>

      <!--      Multiselect-->
      <div
        class="d-flex flex-wrap pt-2 px-2 align-items-center border border-top-0 rounded-bottom overflow-hidden"
        v-if="multiselect && selectedArr.length > 0"
      >
        <TransitionGroup name="list">
          <div
            v-for="(item, index) in selectedArr"
            :key="item"
            class="py-1 bg-secondary rounded-pill mr-2 mb-2"
          >
            <div class="d-flex justify-content-between align-items-center px-2">
              <div class="overflow-hidden small fw-semibold">
                {{ renderText(item) }}
              </div>
              <button
                class="btn btn-sm btn-link small text-muted mx-2 p-0"
                @click.prevent="removeSelection(index)"
              >
                <font-awesome-icon class="text-muted small" icon="times" />
              </button>
            </div>
          </div>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MultiSelect",
  template: "Dropdown",
  emits: [
    "selected",
    "removed",
    "update:modelValue",
    "cleared",
    "filter",
    "blur",
  ],
  props: {
    inputClass: {
      type: String,
      require: false,
      default: "",
    },
    size: {
      type: String,
      require: false,
      validator: function (value) {
        return ["lg", "sm", ""].includes(value);
      },
    },
    icon: {
      type: String,
      require: false,
    },
    filterable: {
      type: Boolean,
      require: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      require: false,
      default: false,
    },
    preSelected: {
      type: [Number, String, Array],
      require: false,
    },
    name: {
      type: String,
      required: false,
      default: "multi-select",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      note: "Options of dropdown. An array of options with id and name",
    },
    modelValue: {
      type: Array,
    },
    fields: {
      type: Array,
      required: true,
      note: "Map of keys for the option array",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      note: "Max items showing",
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: true,
    },
    separator: {
      type: String,
      default: " ",
    },
  },
  data() {
    return {
      selected: {},
      selectedArr: [],
      optionsShown: false,
      inputText: "",
      activeIndex: -1,
    };
  },
  async created() {
    document.addEventListener("click", this.handleGlobalClick);
    if (this.multiselect) {
      this.selectedArr = (await this.preSelected) ? this.preSelected : [];
      if (this.preSelected) {
        this.$emit("selected", this.selectedArr);
        this.$emit(
          "update:modelValue",
          this.preSelected ? this.selectedArr : undefined
        );
      }
    } else {
      let option = await this.getOption(await this.preSelected);
      this.selected = option ? option : {};
      this.inputText = option ? this.renderText(option) : "";
      if (option) {
        this.$emit("selected", this.selected);
        this.$emit(
          "update:modelValue",
          option ? this.selected[this.fields[0]] : undefined
        );
      }
    }
  },
  beforeUnmount() {
    // Remove the global click event listener when the component is destroyed
    document.removeEventListener("click", this.handleGlobalClick);
  },
  computed: {
    filteredOptions: function () {
      if (this.filterable) {
        const filtered = [];
        const regOption = new RegExp(this.inputText, "ig");
        for (const option of this.options) {
          if (this.inputText.length < 1 || this.search(option, regOption)) {
            if (this.maxItem) {
              if (filtered.length < this.maxItem) {
                filtered.push(option);
              }
            } else {
              filtered.push(option);
            }
          }
        }
        return filtered;
      }
      return this.options;
    },
  },
  methods: {
    // searches in all given attribute in the fields prop
    focus() {
      this.$refs["dropdown-toggle"].focus();
    },
    search(option, regOption) {
      let flag = false;
      this.fields.forEach((field, index) => {
        if (index !== 0) {
          // skips the first filed as it is id field
          if (option[field].match(regOption)) {
            flag = true; // if match change flag
          }
        }
      });
      return flag;
    },

    // searches the given preselected id
    getOption(value) {
      let option = undefined;
      this.options.forEach((item) => {
        if (item[this.fields[0]] === value) {
          option = item;
        }
      });

      return option;
    },

    handleEnterPress() {
      if (this.activeIndex > -1) {
        this.selectOption(this.filteredOptions[this.activeIndex]);
        this.exit();
      }
    },

    activeClas(index) {
      if (index == this.activeIndex) {
        return "dropdown-active";
      }
    },

    navigateDown() {
      if (this.activeIndex < this.filteredOptions.length - 1) {
        this.activeIndex++;
      }
    },
    navigateUp() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },

    selectOption(option) {
      if (this.multiselect) {
        if (this.isSelected(option)) {
          const index = this.selectedArr.findIndex(
            (item) => JSON.stringify(item) === JSON.stringify(option)
          );
          this.removeSelection(index);
        } else {
          this.selectedArr.push(option);
          this.$emit("selected", this.selectedArr);
          this.$emit("update:modelValue", this.selectedArr);
        }
      } else {
        this.selected = option;
        this.inputText = this.renderText(option);
        this.$emit("selected", this.selected);
        this.$emit("update:modelValue", this.selected[this.fields[0]]);
      }
      // this.optionsShown = false;
    },

    isSelected(option) {
      if (this.multiselect) {
        return this.selectedArr.find(
          (item) => JSON.stringify(item) === JSON.stringify(option)
        );
      } else {
        return JSON.stringify(option) === JSON.stringify(this.selected);
      }
    },

    removeSelection(index) {
      if (this.multiselect) {
        this.selectedArr.splice(index, 1);
        this.$emit("removed", this.selectedArr);

        const length = this.selectedArr.length;

        // if array is empty then update the v-model with undefined
        if (length > 0) {
          this.$emit("update:modelValue", this.selectedArr);
        } else {
          this.$emit("update:modelValue", []);
        }
      }
    },

    // sets the input and drop down text in case of multiple value attribute
    renderText(option) {
      if (option) {
        let text = "";
        this.fields.forEach((key, index) => {
          if (index !== 0 && option[key]) {
            // skips the first filed as it is id field
            text =
              text +
              option[key] +
              (index !== this.fields.length - 1 ? this.separator : "");
          }
        });

        return text;
      }
      return "";
    },

    async clear() {
      if (this.multiselect) {
        await this.$emit("cleared", this.selectedArr);
      } else {
        await this.$emit("cleared", this.selected);
      }
      this.$emit("update:modelValue", undefined);
      this.selected = {};
      this.selectedArr = [];
      this.inputText = "";
    },

    async showOptions() {
      if (!this.disabled) {
        if (this.filterable) {
          this.inputText = "";
        }
        let t;
        let el = this.$refs["dropdown-toggle"];
        let menu = this.$refs["dropdown-menu"];
        let scrollTop = (((t = document.documentElement) ||
          (t = document.body.parentNode)) &&
        typeof t.scrollTop == "number"
          ? t
          : document.body
        ).scrollTop;
        let topOffset = el.getBoundingClientRect().top;
        let relativeOffset = topOffset - scrollTop;
        let windowHeight = window.innerHeight;
        let bottom = windowHeight - topOffset;

        if (bottom > 300) {
          menu.classList.remove("reverse");
        } else {
          menu.classList.add("reverse");
        }
        this.optionsShown = true;
      }
    },

    handleGlobalClick(event) {
      // Check if the clicked element is outside the dropdown
      const isOutsideDropdown =
        !this.$refs["dropdown-toggle"].contains(event.target) &&
        !this.$refs["dropdown-menu"].contains(event.target);

      // If the click is outside the dropdown, close the menu
      if (isOutsideDropdown) {
        this.exit();
      }
    },

    exit() {
      if (typeof this.selected[this.fields[0]] === "undefined") {
        this.selected = {};
        this.inputText = "";
      } else {
        this.inputText = this.renderText(this.selected);
        this.$emit("update:modelValue", this.selected[this.fields[0]]);
        this.$emit("blur", this.selected[this.fields[0]]);
      }

      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
  },

  watch: {
    inputText() {
      if (this.filterable) {
        this.$emit("filter", this.inputText);
      }
    },

    // Watch if pre selected changes
    async preSelected() {
      let option = await this.getOption(this.preSelected);
      this.selected = option ? option : {};
      this.inputText = option ? this.renderText(option) : "";
      if (option) {
        this.$emit("selected", this.selected);
        this.$emit(
          "update:modelValue",
          option ? this.selected[this.fields[0]] : undefined
        );
      }
    },

    modelValue(newValue) {
      this.selectedArr = newValue;
    },
  },
});
</script>

<style lang="scss" scoped></style>
