
import { computed, defineComponent, ref } from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ConfirmationModal from "@/components/modals/ConfirmationModal.vue";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import RosterCell from "@/views/roster/RosterCell.vue";
import Datepicker from "@vuepic/vue-datepicker";
import { useStore } from "vuex";
import { IAssignee, IDay, IShift } from "@/types/Roster";

export default defineComponent({
  name: "ComplexRoster",
  components: {
    Datepicker,
    RosterCell,
    ConfirmationModal,
    FontAwesomeIcon,
  },

  setup() {
    const modal = ref();

    const currentKey = ref(0);
    const generateKey = () => {
      return (currentKey.value++).toString() + Date.now().toString();
    };

    const generateDate = (rosterIndex: number) => {
      const lastDate = new Date(rosters.value[rosterIndex].lastDate);
      const generatedDate = new Date(
        lastDate.setDate(lastDate.getDate() + 1)
      ).toDateString();
      return generatedDate;
    };

    const store = useStore();

    const api = new WorkForceService();

    const initStartTime = ref(null as any);
    const initEndTime = ref(null as any);

    const rosters = computed(() => store.getters.rosters);

    // flags
    const isEditing = (
      rosterIndex: string | number,
      dayIndex: string | number,
      cellIndex: string | number,
      isDay = false
    ) => {
      if (isDay && dayEditFlag.value) {
        if (
          rosterIndex == editingRosterIndex.value &&
          dayIndex == editingDayIndex.value
        ) {
          return true;
        }
      }
      return (
        rosterIndex == editingRosterIndex.value &&
        dayIndex == editingDayIndex.value &&
        cellIndex == editingCellIndex.value
      );
    };

    const editingRosterIndex = computed(() => {
      return store.getters.editingRosterIndex;
    });
    const editingDayIndex = computed(() => {
      return store.getters.editingDayIndex;
    });
    const editingCellIndex = computed(() => {
      return store.getters.editingCellIndex;
    });
    const dayEditFlag = computed(() => {
      return store.getters.dayEditFlag;
    });
    const isCreatingOrCloningFlag = computed(() => {
      return store.getters.isCreatingOrCloningFlag;
    });

    const resetFlags = () => {
      store.commit("resetFlags");
    };

    const employeeList = ref([]);

    const fetch = () => {
      api.get(`/Employee/BelongToRole`, false).then((response: any) => {
        employeeList.value = response.content.items;
      });
    };
    fetch();

    const editCell = (
      rosterIndex: string | number,
      dayIndex: string | number,
      cellIndex: string | number,
      dayEdit = false
    ) => {
      const payload = {
        editingRosterIndex: rosterIndex,
        editingDayIndex: dayIndex,
        editingCellIndex: cellIndex,
        dayEditFlag: dayEdit,
      };
      store.commit("editCell", payload);
    };

    const cloneDay = (rosterIndex: number, dayIndex: number) => {
      generateDate(rosterIndex);
      const data = JSON.parse(
        JSON.stringify(rosters.value[rosterIndex].days[dayIndex])
      );
      data.date = generateDate(rosterIndex);
      const payload = {
        rosterIndex: rosterIndex,
        data: data,
      };

      store.commit("cloneDay", payload);
    };

    const addDay = (rosterIndex: number) => {
      const data = {
        date: generateDate(rosterIndex),
        isHoliday: false,
        shifts: [] as IShift[],
      } as IDay;

      const payload = {
        rosterIndex: rosterIndex,
        data: data,
      };

      store.commit("addDay", payload);
    };

    const saveDay = () => {
      store.dispatch("saveRosterDay");
    };

    const cancelCreatingDay = (rosterIndex: number) => {
      const data = {
        rosterIndex: rosterIndex,
      };
      store.commit("cancelCreatingDay", data);
      store.commit("resetFlags");
    };

    const addCell = (rosterIndex: number, dayIndex: number) => {
      const data = {
        key: generateKey(),
        startTime: "00:00",
        endTime: "00:00",
        totalHours: 0,
        statuses: [1],
        assignedEmployees: [] as IAssignee[],
      };

      const backupPayload = {
        editingRosterIndex: rosterIndex,
        editingDayIndex: dayIndex,
      };

      store.commit("createBackup", backupPayload);

      const payload = {
        rosterIndex: rosterIndex,
        dayIndex: dayIndex,
        data: data,
      };
      store.commit("addCell", payload);

      const editPayload = {
        editingRosterIndex: rosterIndex,
        editingDayIndex: dayIndex,
        editingCellIndex:
          rosters.value[rosterIndex].days[dayIndex].shifts.length - 1,
        dayEditFlag: false,
      };
      store.commit("editCell", editPayload);
    };

    const isActionCellDisabled = (rosterIndex: number, dayIndex: number) => {
      return (
        rosterIndex == editingRosterIndex.value ||
        (isCreatingOrCloningFlag.value != -1 &&
          Number(dayIndex) + 1 !== rosters.value[rosterIndex].days.length)
      );
    };

    const isCreatingOrCloning = (rosterIndex: number, dayIndex: number) => {
      const isSameRoster = rosterIndex === isCreatingOrCloningFlag.value;
      return (
        isSameRoster &&
        Number(dayIndex) + 1 == rosters.value[rosterIndex].days.length &&
        isCreatingOrCloningFlag.value > -1
      );
    };

    return {
      fetch,
      rosters,
      addCell,
      cloneDay,
      modal,
      initEndTime,
      initStartTime,
      employeeList,
      editCell,
      isEditing,
      resetFlags,
      isCreatingOrCloning,
      generateKey,
      editingRosterIndex,
      editingDayIndex,
      isActionCellDisabled,
      addDay,
      cancelCreatingDay,
      saveDay,
    };
  },
});
